import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
// import AppLogo from '../../../images/NavIcon.png';

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useNavStyles from "./Styles/NavStyles";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import SettingsIcon from '@material-ui/icons/Settings';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import SideDrawer from "./SideDrawer";
import QuickInfoDrawer from "../Controls/QuickInfoDrawer";
import { Avatar, Badge, Box, Button, ButtonBase, Divider, Grid, List, ListSubheader, Menu, MenuItem, Paper, Popover, useMediaQuery, useTheme } from "@material-ui/core";
import AuthContext from "../../Auth/AuthContext";
import AppIcon from "../Controls/AppIcons";
import useStore from "../../Base/Store";
import shallow from 'zustand/shallow';
import DialogWindow from "../Controls/DialogWindow";
import PriceList from "../../Product/PriceList";
import * as Utility from "../../Base/Utils";
import Notifications from "../../User/Notifications";
import { APP_MODULE_MultiUser } from "../../Base/Common";
import AuditInfo from "../../User/Audit/AuditInfo";
import Search from "../../Shared/Search";
import HelpVideos from "../../Shared/Help/HelpVideos";
import { ContextMenuItem } from "../Controls/Controls";
import HelpMenu from "./HelpMenu";
import CalculateRates from "../../Shared/Utils/CalculateRates";
import './style.css';
// import Offer from "../../SalesSupport/Campaings/Offer";
import Tour from 'reactour';
import RealseNotes from "../RealeseNotes/RealeseNote";
import QuickAddDeskTop from "./QuickAddDeskTop";
import MobileSideDrawer from "./MobileSideMenu";
import Unpin from '../../../images/unPin.png'
import { getCompanyList, UpdateUserSubscriberLinkPrimary } from "../../User/Subscriber";
import WebAPI from "../../Base/WebAPI";
import axios from "axios";
import { useConfirm } from "material-ui-confirm";


function NavMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const confirm = useConfirm();
    const onboardingSteps = [
        {
            selector: '.OnboardingNavMenu',
            content: 'Click to update your organisation number.',
        },
    ];

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const auditDetails = useStore(state => state.auditDetails);
    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);
    const [notificationCount, setNotificationCount] = useStore(state => [state.notificationCount, state.setNotificationCount], shallow);
    const resetStore = useStore(state => state.resetStore);
    const multiUserAccess = authContext.moduleAccess(APP_MODULE_MultiUser);

    const [isDialogOpen, openDialog] = useStore(state => [state.isDialogOpen, state.openDialog], shallow);
    const [isMobileDrawerOpen, toggleMobileDrawer] = useStore(state => [state.isMobileDrawerOpen, state.toggleMobileDrawer], shallow);
    const [isInfoDrawerOpen, toggleInfoDrawer] = useStore(state => [state.isInfoDrawerOpen, state.toggleInfoDrawer], shallow);
    const [isSearchDrawerOpen, toggleSearchDrawer] = useStore(state => [state.isSearchDrawerOpen, state.toggleSearchDrawer], shallow);
    const [isHelpDrawerOpen, toggleHelpDrawer] = useStore(state => [state.isHelpDrawerOpen, state.toggleHelpDrawer], shallow);
    const [isAuditDrawerOpen, toggleAuditDrawer] = useStore(state => [state.isAuditDrawerOpen, state.toggleAuditDrawer], shallow);
    const [helpAnchorEl, setHelpAnchorEl] = React.useState(null);
    const [dialogResponse, setDialogResponse] = useStore(state => [state.dialogResponse, state.setDialogResponse], shallow);
    const [isSettingsDrawerOpen, toggleSettingsDrawer] = useStore(state => [state.isSettingsDrawerOpen, state.toggleSettingsDrawer], shallow);
    const [sidebarToggle, setSidebarToggle] = useState(false);
    const [isInvoiceFullScreenToggle, toggleInvoiceScreen] = useStore(state => [state.isInvoiceFullScreenToggle, state.toggleInvoiceScreen], shallow);
    const [companyProfileTab, toggleCompanyProfileTab] = useStore(state => [state.companyProfileTab, state.toggleCompanyProfileTab], shallow);

    const [isTourOpen, setIsTourOpen] = useState(false);
    const [anchorElSecond, setAnchorElSecond] = useState(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const showAlert = useStore(state => state.showAlert);



    const [pinSideBarMenu, setPinSideBarMenu] = useState(false);


    const handleClickQuickAdd = (event) => {
        setAnchorElSecond(event.currentTarget);
    };

    const handleCloseQuickAdd = () => {
        setAnchorElSecond(null);
    };


    const classes = useNavStyles();
    // const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
    const handleDrawerToggle = () => {
        toggleMobileDrawer(!isMobileDrawerOpen);
    };
    const handleAuditDrawerToggle = () => {
        toggleAuditDrawer(!isAuditDrawerOpen);
    };
    const handleSettingsDrawerToggle = () => {
        toggleSettingsDrawer(!isSettingsDrawerOpen);
        setAnchorEl(null);
    };

    const handleInfoDrawerToggle = () => {
        toggleInfoDrawer(!isInfoDrawerOpen);
    };
    const handleSearchDrawerToggle = () => {
        toggleSearchDrawer(!isSearchDrawerOpen);
    };

    const handleHelpDrawerToggle = () => {
        toggleHelpDrawer(!isHelpDrawerOpen);
    };

    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleCloseMobileDrawer = () => {
        setMobileMenuOpen(false)
    }

    const handleOpenMobileDrawer = () => {
        setMobileMenuOpen(true);
    }


    const logoutUser = () => {
        try {
            setWaiting(true);
            authContext.logout();
            setTimeout(() => {
                resetStore();
                history.replace("");
                setWaiting(false);
            }, 1000);
        } catch (error) {
            setWaiting(false);
            console.log(error);
        }
    }

    function navMenuClickHandler(e) {
        if (e.currentTarget.attributes.path.value === '/pricelist') {
            openDialog('priceListDialog')
        } else if (e.currentTarget.attributes.path.value === '/notifications') {
            toggleInfoDrawer(true);
        } else if (e.currentTarget.attributes.path.value === '/search') {
            toggleSearchDrawer(true);
        } else {
            history.push(e.currentTarget.attributes.path.value);
        }
        setMobileMoreAnchorEl(null);
        toggleMobileDrawer(false);
    }

    const mobileMenuId = 'mobileMenuActions';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            // anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            // transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            id={mobileMenuId}
            keepMounted
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
            className={classes.appBar}
            style={{ background: 'transparent' }}
        >
            <MenuItem path="/user-profile" onClick={navMenuClickHandler}>
                <IconButton size="small" aria-label="Manage business profile" color="inherit" title="Manage business profile">
                    <AppIcon name="Business" size="small" />
                </IconButton>
                <p style={{ whiteSpace: "nowrap", overflow: 'hidden', textOverflow: 'ellipsis' }}>{!Utility.isNullOrUndefined(authContext.currentSubscriber) ? Utility.safeString(authContext.currentSubscriber.legalName) : ""}</p>
            </MenuItem>
            {
                Utility.safeBool(multiUserAccess.allowAdd) &&
                <MenuItem path="/users" onClick={navMenuClickHandler}>
                    <IconButton size="small" aria-label="Manage users" color="inherit" title="Manage users">
                        <AppIcon name="ManageUser" />
                    </IconButton>
                    <p>Manage users</p>
                </MenuItem>
            }
            <MenuItem path="/changePassword" onClick={navMenuClickHandler}>
                <IconButton size="small" aria-label="Change Password" color="inherit" title="Change Password">
                    <AppIcon name="Password" fontSize="small" />
                </IconButton>
                <p>Change Password</p>
            </MenuItem>
            <MenuItem onClick={handleSettingsDrawerToggle}>
                <IconButton size="small" aria-label="Manage application settings" color="inherit" title="Manage application settings">
                    <SettingsIcon fontSize="small" />
                </IconButton>
                <p>App settings</p>
            </MenuItem>
            <MenuItem path="/subscription" onClick={navMenuClickHandler}>
                <IconButton size="small" aria-label="View Subscription" color="inherit" title="View Subscription">
                    <AppIcon name="Subscription" fontSize="small" />
                </IconButton>
                <p>View Subscription</p>
            </MenuItem>
            <MenuItem onClick={() => logoutUser()}>
                <IconButton size="small" aria-label="Signout" title="Signout">
                    <PowerSettingsNewIcon fontSize="small" style={{ fill: 'red' }} />
                </IconButton>
                <p className={classes.dangerColor}>Logout</p>
            </MenuItem>
        </Menu>
    );

    const handleMenuItemClick = async (action) => {
        setAnchorEl(null);
        switch (action) {
            case "Logout":
                logoutUser();
                break;
            case "AddCompany":
                alert('Coming soon...!');
                // history.push("/add-company");
                break;
            case "Setting":
                history.push("/appsettings");
                break;
            case "ManageProfile":
                history.push("/user-profile");
                break;
            case "Subscription":
                history.push("/subscription");
                break;
            case "ChangePassword":
                history.push("/changePassword");
                break;
            case "ManageUsers":
                history.push("/users");
                break;
            default:
                break;
        }
    }

    const openHelp = (event) => {
        setHelpAnchorEl(event.currentTarget);
    }

    const handleHelpClose = () => {
        setHelpAnchorEl(null);
    };

    const handleDialogClosed = (dialogName, setFieldValue, values = null) => {
        try {
            if (dialogResponse != null) {
                switch (dialogName) {
                    case 'calculateRatesDialog':
                        setFieldValue('unitPrice', Utility.safeFloat(dialogResponse));
                        Utility.setInputFocus('unitPrice');

                        break;
                    default:
                        break;
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setDialogResponse(null);
        }
    };

    useEffect(() => {
        const onboardingCompleted = localStorage.getItem('onboardingCompletedUserProfile');

        if (onboardingCompleted === 'true') {
            localStorage.setItem('onboardingCompletedNavbar', 'true');
            setIsTourOpen(false);
        } else {
            if (authContext.currentSubscriber.taxIdNumber === '000000000000000') {
                setTimeout(() => {
                    setIsTourOpen(true);
                }, 5000);
            }
        }

    }, [])

    const closeTour = () => {
        setIsTourOpen(false);
    };
    const autoHideSidebar = JSON.parse(localStorage.getItem('autoHideSidebar'));


    const handleSideBarMenu = () => {
        const newPinSideBarMenu = !pinSideBarMenu;
        setPinSideBarMenu(newPinSideBarMenu);
        toggleInvoiceScreen(newPinSideBarMenu);
        localStorage.setItem('autoHideSidebar', JSON.stringify(newPinSideBarMenu));
    };

    const getList = async (token) => {
        try {
            setWaiting(true);
            var response = await getCompanyList(token, authContext);
            if (!Utility.isNullOrUndefined(response.data)) {
                if (Utility.safeInt(response.data.code) > 0) {
                    showAlert(response.data.message, 'error');
                } else {
                    setCompanyList(response.data);
                }
            }
        } catch (error) {
            if (WebAPI.isCancel(error) === true) {
                console.log('Company list: request cancelled.');
            } else {
                console.log(error);
                showAlert('Failed to load Company list. Internal sever error occured.', 'error');
            }
        } finally {
            setWaiting(false);
        }
    }

    useEffect(() => {
        if (autoHideSidebar !== null) {
            setPinSideBarMenu(autoHideSidebar);
            toggleInvoiceScreen(autoHideSidebar);
        }
        let source = axios.CancelToken.source();
        async function init() {
            await getList(source.token);
        }
        init();
        return () => {
            source.cancel();
        };
    }, []);

    const uploadCompanyProfile = () => {
        toggleSettingsDrawer(true);
        toggleCompanyProfileTab(true);
    }

    const companyChangeHandler = (company) => {
        confirm(Utility.getConfirmOptions('Change Company Session?',
            `Do you want switch session to company: ${company.legalName}?`, 'Yes', 'No'))
            .then(async () => {
                await UpdateUserSubscriberLinkPrimary(company.subscriberId, authContext, setWaiting, showAlert, history);
            }).catch(() => { });
    }


    return (
        <>
            <CssBaseline />
            <QuickAddDeskTop anchorEl={anchorElSecond} handleClose={handleCloseQuickAdd} />
            {
                (!mobileDevice && isTourOpen) && <>
                    <Tour
                        onRequestClose={closeTour}
                        steps={onboardingSteps}
                        isOpen={isTourOpen}
                        maskSpace={10}
                        /*  maskClassName="mask" */
                        className="helper"
                        rounded={10}
                        showButtons={true}
                        accentColor="#007bff"
                        lastStepNextButton={<Button variant="outlined" onClick={() => history.push('/user-profile')}>Next</Button>}
                    />

                </>
            }
            <HelpMenu HelpOpen={helpAnchorEl} onClose={handleHelpClose} helpAnchorEl={helpAnchorEl} />
            <AppBar position="fixed" className={classes.appBar} >
                <Toolbar className={classes.navToolBar}>
                    {
                        mobileDevice &&
                        <span>
                            <IconButton
                                color="inherit"
                                aria-label="open side drawer"
                                edge="start"
                                onClick={handleOpenMobileDrawer}
                                className={classes.menuButton}>
                                {!isMobileDrawerOpen ? <MenuIcon /> : <CloseIcon />}
                            </IconButton>
                            <ButtonBase onClick={() => history.push("/")} title="Click to go to home screen." >
                                <span style={{ fontWeight: 'bolder', fontSize: '1.8em', width: '72px' }}>
                                    <span style={{ color: '#17A2B8' }}>ezy</span><span style={{ color: '#00C853' }}>bill</span>
                                </span>
                            </ButtonBase>
                        </span>
                    }

                    <Box style={{ marginLeft: mobileDevice ? '0px' : '20px' }}>
                        {
                            !mobileDevice &&
                            <ButtonBase onClick={() => toggleInvoiceScreen(!isInvoiceFullScreenToggle)} style={{ marginLeft: '-30px' }} title="Click to toggle side menu">
                                {
                                    isInvoiceFullScreenToggle ? <MenuIcon /> : <CloseIcon />
                                }

                            </ButtonBase>
                        }
                        {
                            !mobileDevice &&
                            <>
                                <ButtonBase onClick={() => history.push("/")} className={classes.ml2} title="Click to go to home screen. Shortcut (Alt+H)">
                                    <span style={{ fontWeight: 'bolder', fontSize: '1.8em', width: '72px' }}>
                                        <span style={{ color: '#17A2B8' }}>ezy</span><span style={{ color: '#00C853' }}>bill</span>
                                    </span>
                                </ButtonBase>


                                <ButtonBase onClick={handleSideBarMenu} style={{ marginLeft: '54px' }} title={!autoHideSidebar ? ` Click to auto hide side menu while on invoice/quotation/purchase bill etc. screens.` : ` Click to pin (always show) the side menu.`}>
                                    {
                                        !isInvoiceFullScreenToggle ?
                                            <>
                                                {autoHideSidebar ? <img src={Unpin} width={"18px"} style={{ color: "#fff" }} alt="pin" /> : <AppIcon name="PIN" size="xs" />}
                                            </> : null
                                    }
                                </ButtonBase>
                            </>
                        }

                        {/* {
                            !mobileDevice &&
                            <ButtonBase onClick={() => history.goForward()} title="Click to go forward (Shortcut: Alt + Right Arrow key)">
                                <AppIcon name="RightChevron" size="medium" style={{ color: '#17A2B8' }} />
                            </ButtonBase>
                        } */}
                        {
                            !mobileDevice ?
                                <>
                                    <Button onClick={(e) => handleClickQuickAdd(e)} variant="outlined"
                                        style={{ color: "#fff", marginLeft: '19px', border: '1px solid #4CAF50', padding: '3px 7px', background: '#4CAF50' }}
                                        startIcon={<AppIcon name="Flash" />}>
                                        <Typography variant="caption" style={{ marginTop: '2px' }}>Quick Create</Typography>
                                    </Button>

                                </> : null
                        }
                    </Box>
                    {/*  {
                        !mobileDevice ? <Offer /> : null
                    } */}


                    <div className={classes.grow} />

                    {
                        mobileDevice && !Utility.isNullOrUndefined(auditDetails) &&
                        <IconButton size="small" aria-label="View audit details" title="View audit details"
                            path="/auditDetails" onClick={handleAuditDrawerToggle} className={`${classes.infoColor} ${classes.link}`}>
                            <AppIcon name="UserAudit" />
                            {/* <Typography className={classes.infoColor} variant="body2">{Utility.shortenString(auditDetails.createUserName, 6)}</Typography> */}
                        </IconButton>
                    }

                    <div className={classes.sectionDesktop}>
                        <div className="wrap" >
                            <div className={`${classes.pullRight} search`} path="/search" onClick={navMenuClickHandler}>
                                <input type="text" className="searchTerm" placeholder="Search (Shortcut Alt+L)" />
                                <button type="submit" className="searchButton">
                                    <AppIcon name="Search" size="small" style={{ color: 'whitesmoke' }} />
                                </button>
                            </div>
                        </div>

                        <IconButton size="small" aria-label="Price List" color="inherit" title="Price List"
                            path="/pricelist" onClick={navMenuClickHandler} className={classes.ml2}>
                            <AppIcon name="History" size="medium" style={{ color: 'lightseagreen' }} />
                        </IconButton>

                        <IconButton size="small" onClick={() => openDialog('calculateRatesDialogNav')} aria-label="Calculator" title="Click to calculate price including/excluding tax rate."
                            className={classes.ml2}>
                            <AppIcon name="Calculator" size="small" color="white" />
                        </IconButton>
                        <span style={{ border: '1px solid #F0A150', borderRadius: '5px', paddingRight: '4px', paddingTop: '2px', background: '#F0A150' }} className={`${classes.ml2}`}>
                            <IconButton size="small" aria-label="Help/Support" title="Help/Support"
                                onClick={openHelp} >
                                <AppIcon name="LiveHelp" style={{ color: "#232F3E", background: '#F0A150' }} />
                                <Typography variant="body2" style={{ color: '#232F3E', marginLeft: '5px', marginRight: '5px', fontWeight: '500' }}>Support</Typography>
                            </IconButton>
                        </span>
                        {
                            authContext.settings.enableNotifications &&
                            <IconButton size="small" aria-label="Notifications" color="inherit" title="Notifications"
                                path="/notifications" onClick={handleInfoDrawerToggle} className={classes.ml2}>
                                <Badge className="OnboardingScreenopenDropdown" overlap="rectangular" badgeContent={notificationCount} color="error">
                                    <AppIcon name="Notifications" size="small" style={{ color: 'oldlace' }} />
                                </Badge>
                                {/* <Typography variant="subtitle2" style={{ marginLeft: '10px' }}></Typography> */}
                            </IconButton>
                        }
                        <div>
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                                color="secondary"
                                disableRipple={true}
                            >
                                <IconButton disableRipple={true}
                                    size="small" aria-label="Manage business profile" title="Manage business profile" style={{ color: '#fff' }}
                                    className={`${classes.ml2} OnboardingNavMenu `}>
                                    <AppIcon name="Business" size="small" /><Typography variant="subtitle2" style={{ marginLeft: '5px', marginTop: '5px' }}>{(!Utility.isNullOrUndefined(authContext.currentSubscriber) ? Utility.shortenString(authContext.currentSubscriber.legalName, 25) : "")} </Typography>  <AppIcon name="ExpandMore" size="small" />
                                </IconButton>
                            </IconButton>

                        </div>
                    </div>

                    <div className={`${classes.sectionMobile}`}>
                        <IconButton size="small" aria-label="Search" color="inherit" title="Search"
                            path="/search" onClick={navMenuClickHandler}>
                            <AppIcon name="Search" size="medium" style={{ color: 'whitesmoke' }} /> <Typography variant="subtitle2"></Typography>
                        </IconButton>
                        <IconButton size="small" aria-label="Help/Support" title="Help/Support"
                            onClick={openHelp} className={classes.ml2}>
                            <AppIcon name="LiveHelp" size="medium" style={{ color: "#F0A150", background: 'transparent' }} />
                            {/* <HelpIcon fontSize="small" color="secondary" style={{ color: "white", background: 'MediumSlateBlue' }} /> */}
                        </IconButton>
                        {
                            authContext.settings.enableNotifications &&
                            <IconButton size="small" aria-label="Notifications" color="inherit" title="Notifications"
                                path="/notifications" onClick={handleInfoDrawerToggle} className={classes.ml2}>
                                <Badge overlap="rectangular" badgeContent={notificationCount} color="error">
                                    <AppIcon name="Notifications" size="medium" />
                                </Badge>
                            </IconButton>
                        }

                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleClick}
                            color="inherit"
                            className={classes.ml2}
                        >
                            <MoreVertIcon size="medium" />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>

            {renderMobileMenu}

            {
                !Utility.isNullOrUndefined(authContext) && !Utility.isNullOrUndefined(authContext.currentSubscriber) &&
                authContext.currentSubscriber.id > 0 &&
                <SideDrawer open={isMobileDrawerOpen} onClose={handleDrawerToggle} sidebarToggle={sidebarToggle} />
            }

            <MobileSideDrawer open={mobileMenuOpen} handleOpenMobileDrawer={handleCloseMobileDrawer} />


            <DialogWindow name="priceListDialog" open={isDialogOpen}
                width="md" component={<PriceList isModal={true} />} />

            <QuickInfoDrawer isInfoDrawerOpen={isInfoDrawerOpen}
                toggleDrawerOpen={handleInfoDrawerToggle}
                title={'Notifications'} content={<Notifications />} />

            <QuickInfoDrawer isInfoDrawerOpen={isSearchDrawerOpen}
                toggleDrawerOpen={handleSearchDrawerToggle}
                title={'Search'} content={<Search />} anchor="top" />

            <QuickInfoDrawer isInfoDrawerOpen={isAuditDrawerOpen}
                toggleDrawerOpen={handleAuditDrawerToggle}
                title={'Audit details'} content={<AuditInfo />} />

            <QuickInfoDrawer isInfoDrawerOpen={isHelpDrawerOpen}
                toggleDrawerOpen={handleHelpDrawerToggle}
                title={'Help'} content={<HelpVideos />} />

            <span className={`${classes.statusBar}`}>
                {
                    !mobileDevice && !Utility.isNullOrUndefined(auditDetails) &&
                    <Button color="secondary" variant="text" startIcon={<AppIcon name="UserAudit" />} onClick={handleAuditDrawerToggle}
                        title="View audit details">
                        {Utility.safeString(auditDetails.createUserName) + ", " + Utility.getLocaleDateTime(auditDetails.dateCreated)}
                    </Button>
                }
            </span>

            <Popover
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                className={classes.profileRoot}
                elevation={3}
            >
                <div className={classes.profileHeader}>
                    <div className={classes.avatarWrapper}>
                        {
                            !Utility.isNullOrEmpty(authContext.currentSubscriber.logo) ? (
                                <Avatar
                                    alt="User Avatar"
                                    src={authContext.currentSubscriber.logo}
                                    variant="rounded"
                                    style={{ width: '70px', height: 'auto' }}
                                    className={classes.profileAvatar}
                                />
                            ) : (
                                <Avatar
                                    style={{ width: '70px', height: '70px' }}
                                    className={classes.profileAvatar}
                                    variant="rounded"
                                    alt="User Avatar"
                                >
                                    <AppIcon name="Business" />
                                </Avatar>
                            )
                        }

                        <div className={classes.editOverlay}>
                            <IconButton onClick={() => uploadCompanyProfile()}>
                                <AppIcon name="Edit" style={{ color: '#fff' }} />
                            </IconButton>
                        </div>
                    </div>

                    <Typography variant="h6" className={classes.profileName} style={{ cursor: 'pointer' }} onClick={() => {
                        history.push('/user-profile');
                        handleClose();
                    }}>
                        {authContext.currentSubscriber.legalName}
                    </Typography>
                    <Typography variant="body2" color="secondary" className={classes.profileTaxIdNumber}>
                        {authContext.currentSubscriber.taxIdNumber}
                    </Typography>
                    <Grid container className={classes.profileIconGroup}>
                        <IconButton title="App Settings" onClick={handleSettingsDrawerToggle} className={classes.profileIconButton}>
                            <AppIcon name="Settings" size="small" />
                        </IconButton>
                        <IconButton title="Manage Users" className={classes.profileIconButton} onClick={() => {
                            history.push('/users');
                            handleClose();
                        }}>
                            {
                                Utility.safeBool(multiUserAccess.allowAdd) &&
                                <AppIcon name="ManageUser" size="small" />
                            }
                        </IconButton>
                        <IconButton title="Change Password" className={classes.profileIconButton} onClick={() => {
                            history.push('/changePassword');
                            handleClose()
                        }}>
                            <AppIcon name="Password" size="small" />
                        </IconButton>
                    </Grid>
                </div>
                <div className={classes.profileSection}>
                    <MenuItem onClick={() => handleMenuItemClick('ManageProfile')} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                        <ContextMenuItem label='Edit Business Profile' iconname={'Business'} />
                    </MenuItem>

                    <MenuItem onClick={() => handleMenuItemClick('Subscription')} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                        <ContextMenuItem className="" label='View Subscription' iconname={'Subscription'} />
                    </MenuItem>
                </div>
                <Divider style={{ marginTop: '10px', marginBottom: '15px' }} />
                <div className={classes.profileSectionSubmenu} style={{ height: companyList && companyList.length > 1 ? '180px' : '50px' }}
                >
                    <Typography variant="cpation" className={classes.companyListHeader}>Other Companies</Typography>
                    <List className={classes.companyList}>
                        {companyList
                            ?.filter(company => company.subscriberId !== authContext.currentSubscriber.id)
                            .map((company) => (
                                <MenuItem
                                    key={company.subscriberId}
                                    onClick={() => handleMenuItemClick(company.subscriberId)}
                                    className={`${classes.menuItem} ${classes.menuItemHover}`}
                                >
                                    {
                                        !Utility.isNullOrEmpty(company.logo) ?
                                            <Avatar
                                                alt={company.legalName}
                                                src={company.logo}
                                                variant="rounded"
                                                className={classes.companyAvatar} /> :
                                            <Avatar
                                                className={classes.companyAvatar}
                                                alt={company.legalName}

                                            >
                                                <AppIcon name="Business" />
                                            </Avatar>
                                    }
                                    <Typography variant="subtitle2" className={classes.companyName}>
                                        {company.legalName}
                                    </Typography>
                                    <div
                                        title="Switch Company"
                                        className={classes.rightIcon}
                                        onClick={() => companyChangeHandler(company)}
                                    >
                                        <AppIcon name="Switch" size="lg" color="secondary" />
                                    </div>
                                </MenuItem>
                            ))}

                    </List>
                    <MenuItem onClick={() => handleMenuItemClick('AddCompany')} style={{ margin: 0, padding: 0 }}>
                        <ContextMenuItem label='Add Company' iconname={'Add'} iconColor="primary" labelcolor={"primary"} />
                    </MenuItem>
                </div>

                <Divider style={{ marginTop: '10px', marginBottom: '0px' }} />
                <MenuItem onClick={() => handleMenuItemClick('Logout')} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                    <ContextMenuItem label='Logout' iconname={'Power'} iconColor="error" labelcolor={"error"} />
                </MenuItem>
            </Popover>


            <DialogWindow name="calculateRatesDialogNav" open={isDialogOpen}
                onExited={e => handleDialogClosed('calculateRatesDialogNav')}
                width="xs" component={<CalculateRates isModal={true} showUseRate={false} />} />
            <RealseNotes />
        </>
    );
}

export default NavMenu;
